<template>
  <main class="campaign">
    <div class="header-page">
      <span class="header-title">{{ $t('video.detail.results') }}</span>
    </div>
    <div v-if="!isLoading && !!vastConfig">
      <!--    Summary-->
      <Card>
        <p slot="title">
          {{ $t('video.detail.configuration') }}
        </p>
        <edit-drawer
          v-if="!isLoading"
          @close="closeDrawer"
          @created="getCreated"
          @updated="updateData"
          :is-draft="draft"
          :is-editing="isEditing"
          :data="vastConfig"
        />
        <!-- <p slot="extra">
          <Button
            class="actions"
            type="primary"
            @click="isEditing = !isEditing"
          >
            {{ !vastConfig.id ? $t('video.detail.save_control') : $t('video.detail.edit_control') }}
            <Icon type="md-create" />
          </Button>
          <Button
            v-if="vastConfig.id"
            class="actions"
            type="primary"
            @click="vastConfigInspect"
          >
            {{ $t('video.detail.redo_control') }}
            <Icon type="md-refresh" />
          </Button>
        </p> -->
        <Row :gutter="22">
          <!-- Configuration -->
          <Col :lg="12">
            <table>
              <!--                <tr>-->
              <!--                  <th align="left">-->

              <!--                    Ad vendors average Trust Index-->
              <!--                  </th>-->
              <!--                  <td>: <span :style="{color: riskColor[vendorVigilanceToLetter(vastConfig.last_vendors_vigilance_rating*5)]}"> {{ vendorVigilanceToLetter(vastConfig.last_vendors_vigilance_rating*5) }}</span>-->
              <!--                  </td>-->
              <!--                </tr>-->
              <tr>
                <th align="left">
                  {{ $t('video.detail.campaign') }}
                </th>
                <td>: {{ vastConfig.campaign || dataNull }}</td>
              </tr>
              <tr>
                <th align="left">
                  {{ $t('video.detail.advertiser') }}
                </th>
                <td>: {{ vastConfig.advertiser || dataNull }}</td>
              </tr>
              <tr>
                <th align="left">
                  {{ $t('video.detail.agency') }}
                </th>
                <td>: {{ vastConfig.agency || dataNull }}</td>
              </tr>
              <tr v-if="vastConfig.dealmodel === 'Direct'">
                <th align="left">
                  {{ $t('video.detail.tradingdesk') }}
                </th>
                <td>: {{ vastConfig.tradingdesk || dataNull }}</td>
              </tr>
              <tr>
                <th align="left">
                  {{ $t('video.detail.url_vast') }}
                </th>
                <td>
                  : <a
                    :href="vastConfig.url"
                    target="_blank"
                  >url</a>
                </td>
              </tr>
              <tr>
                <th align="left">
                  {{ $t('video.detail.last_check_date') }}
                </th>
                <td>: {{ dateSimple(vastConfig.modified) || dataNull }}</td>
              </tr>
            </table>
          </Col>
          <!-- Video player-->
          <Col
            :lg="12"
            v-if="!!videoUrl"
            @emitUrl="updateVid"
          >
            <!-- TODO: add placeholder when no url -->
            <video
              controls
              :src="videoUrl"
            />
          </Col>
        </Row>
        <!--       Inspections history graphs -->
        <!-- <Row
          :gutter="22"
          v-if="vastConfig.vastconfig_inspections && vastConfig.vastconfig_inspections.length > 1"
        >
          <Col :lg="12">
            <WidgetTile
              :api-res="widgetMediafilesVendorsHistoryData"
              :is-actionable="true"
              type="small"
            />
          </Col>
          <Col :lg="12">
            <WidgetTile
              :api-res="widgetAllDomainsHistoryData"
              :is-actionable="true"
              type="small"
            />
          </Col>
        </Row> -->
      </Card>
      <br>
      <!--       Inspections history-->
      <Timeline v-if="vastConfig.vastconfig_inspections && vastConfig.vastconfig_inspections.length > 0">
        <!--        <Row :gutter="22" v-if="vastConfig.inspections && vastConfig.inspections.length > 0">-->
        <!--          <Col :lg="24">-->
        <TimelineItem
          v-for="(inspection, inspectionIdx) of vastConfig.vastconfig_inspections"
          :key="inspection.id"
          ref="inspections"
        >
          <template>
            <h4 @click="setVastConfigInspectionFocus(inspectionIdx)">
              {{ dateSimple(inspection.created) }}
            </h4>
          </template>
          <div v-if="inspection.report.VAST">
            <Card v-if="vastConfigInspectionIndex != inspectionIdx">
              <Steps :current="-1">
                <Step
                  v-for="(vastAd, adIdx) of inspection.report.VAST.Ads"
                  :key="adIdx"
                  :title="`${(vastAd.creatives.length && vastAd.creatives[0].vendor_name) || $t('Freewheel')}`"
                  :content="`${(vastAd.creatives.length && vastAd.creatives[0].vendor_category_name) || $t('video.detail.unknown_category')}`"
                />-->
              </Steps>
            </Card>
            <Card v-else>
              <Tabs type="card">
                <TabPane
                  v-for="(vastAd, key) of inspection.report.VAST.Ads"
                  :key="key"
                  :label="`${(vastAd.creatives.length && vastAd.creatives[0].vendor_name) || $t('Freewheel Inventory')}`"
                >
                  <AdContent
                    :vast-ad="vastAd"
                    :loading="isLoading"
                    :vast-config="vastConfig"
                  />
                  <!--                                   :domains="domains"-->
                  <!--                                   :vendors="vendors"/>-->
                </TabPane>
              </Tabs>
              <!--                  <AdContent :vastAd="vastAd"-->
              <!--                             :loading="isLoading"-->
              <!--                             :vastConfig="vastConfig"-->
              <!--                             :domains="domains"-->
              <!--                             :vendors="vendors"/>-->
            </Card>
          </div>
          <div v-else>
            <Card>
              {{ inspection.report.status }}
            </Card>
          </div>
        </TimelineItem>
      </Timeline>
    </div>
  </main>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import WidgetTile from '@/components/ui/WidgetTile'
import AdContent from './campaign/AdContent'
import EditDrawer from './campaign/VastConfigEdit'
import moment from 'moment'
import {eventBus} from '@/main'
// import videojs from 'video.js'
// import 'videojs-contrib-ads'
// import 'videojs-ima'

export default {
  name: 'VastConfig',
  components: { AdContent, EditDrawer},
  data () {
    // return this.renderState()
    return {
      playerObj: null,
      vastConfig: null,
      vastConfigInspectionIndex: 0,
      vastConfigAdIndex: 0,
      isEditing: false,
      videoUrl: null,
      eventBus: eventBus,
      isLoading: true,
      widgetMediafilesVendorsHistoryData: {
        'paging': {},
        'filters': {},
        'name': 'MEDIAFILES VENDORS',
        'properties': {
          '0': 'propertyId'
        },
        'startDate': '2018-05-29',
        'endDate': '2018-06-29',
        'nbDays': 32,
        'propertyNames': {
          '0': 'propertyName'
        },
        'widget': 'STACKEDCHART',
        'title': 'MEDIAFILES VENDORS HISTORY',
        'help': '',
        'export': {
          'csv': ''
        },
        'value': {
          'columns': {
            '0': 'Day'
          },
          'colors': {
            '0': null
          },
          'dailylinks': {
            '0': ''
          },
          'data': {
            '0': {
              '0': '2018-05-29'
            }
          }
        },
        'isExportableCsv': false,
        'csvFileName': 'vendors_by_name'
      },
      widgetAllDomainsHistoryData: {
        'paging': {},
        'filters': {},
        'name': 'ALL DOMAINS',
        'properties': {
          '0': 'propertyId'
        },
        'startDate': '2018-05-29',
        'endDate': '2018-06-29',
        'nbDays': 32,
        'propertyNames': {
          '0': 'propertyName'
        },
        'widget': 'STACKEDCHART',
        'title': 'ALL DOMAINS HISTORY',
        'help': '',
        'export': {
          'csv': ''
        },
        'value': {
          'columns': {
            '0': 'Day'
          },
          'colors': {
            '0': null
          },
          'dailylinks': {
            '0': ''
          },
          'data': {
            '0': {
              '0': '2018-05-29'
            }
          }
        },
        'isExportableCsv': false,
        'csvFileName': 'vendors_by_name'
      }
    }
  },
  mounted () {
    const url = this.$route.path
    const vastUrl = this.$route.query.url
    let id
    // events listeners
    eventBus.$on('video-url-found', url => {
      this.videoUrl = url
    })
    // data fill
    this.$route.path.includes('draft')
      ? id = null
      : this.$route.params.id
        ? id = this.$route.params.id
        : id = url.split('/vast/vast_config/')[0]
    console.log('mounted')
    this.fetchVastConfig(id, vastUrl)
  },
  computed: {
    draft () {
      return this.$route.path.includes('draft')
    },
    dataNull () {
      return this.$t('video.global.no_data')
    }
  },
  methods: {
    ...mapActions({
      getVast: 'video/vasConfigById',
      view: 'video/view',
      putVast: 'video/put',
    }),
    // ...mapActions('video', ['getItem', 'reloadVast']),
    // call from video @emitUrl
    updateVid (elm) {
    },
    _findAndEmitFirstVideoUrl () {
      if (this.vastConfig.vastconfig_inspections.length && this.vastConfig.vastconfig_inspections[this.vastConfigInspectionIndex].report.VAST.Ads) {
        const ads = this.vastConfig.vastconfig_inspections[this.vastConfigInspectionIndex].report.VAST.Ads
        for (let ad of ads) {
          for (let j = 0; j < ad.creatives.length; j++) {
            const creative = ad.creatives[j]
            if (creative.mediafile.includes('mp4') ||
                 creative.mediafile.includes('Ogg') ||
                 creative.mediafile.includes('webm')
            ) {
              eventBus.$emit('video-url-found', creative.mediafile)
              return
            }
          }
        }
      }
    },
    // pause () {
    //   this.playerObj.pause()
    // },
    //
    // player () {
    // },

    vastConfigInspect () {
      this.isLoading = true
      return this.putVast(this.vastConfig.id)
        .then(r => {
          console.log('vastConfigInspect')
          return this.fetchVastConfig(this.vastConfig.id)
        })
    },

    updateData (data) {
      this.closeDrawer()
      // this.vastConfig = Object.assign(this.vastConfig, data)
      // TODO : what is this for ?
      // this.vastConfig.campaign = this.vastConfig.campaign
      // delete this.vastConfig.campaign
    },
    getCreated (id) {
      console.log('getCreated')
      return this.fetchVastConfig(id)
    },
    // errorClass (error) {
    //   return error
    //     ? 'li-warning'
    //     : ''
    // },
    // dateFrom (elm) {
    //   return moment(elm).calendar()
    // },
    dateSimple (elm) {
      return moment(elm).format('LLL')
    },
    setVastConfigInspectionFocus (inspectionIndex) {
      this.vastConfigInspectionIndex = inspectionIndex
      // this.vastConfigAdIndex = adIndex
      // this.scrollToElement('inspections', inspectionIndex)
    },
    // scrollToElement (refName, idx) {
    //   let element = this.$refs[refName][idx]
    //   let bodyRect = document.body.getBoundingClientRect()
    //   let elemRect = element.getBoundingClientRect()
    //   let offset = elemRect.top - bodyRect.top
    //   console.log(offset)
    //   setTimeout(function () {
    //     window.scrollTo(0, offset - 100)
    //   }, 250)
    // },
    /**
     * cut array to display a list
     *
     * @param {Array} list the array
     *
     * @returns {String} str the string created from the array
     */
    // showList (list) {
    //   let str = ''
    //   let k = 0
    //   for (k; k < list.length; k++) {
    //     str = str + ' - ' + list[k]
    //   }
    //   return str
    // },

    // whichVendors () {
    //   return 'which'
    // },

    // showTech () {
    //   this.eventBus.$emit('techDetails')
    // },

    closeDrawer () {
      this.isEditing = false
    },
    // getRandomColor () {
    //   // return '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
    //   return '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6)
    // },
    stringToColor (str) {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let color = '#'
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF
        color += ('00' + value.toString(16)).substr(-2)
      }
      return color
    },
    /**
     * Prepare the api call in order to get the page content
     * @param {String} id the vast ID
     * @returns {Promise} resolve the api answer
     */
    fetchVastConfig (id, url) {
      console.log('fetchVastConfig', id, url)
      const testOrGet = id ? this.getVast : this.view
      return testOrGet({id, url})
        .then(r => {
          if (r === 'error') {
            this.$Message.error('Vast was not found')
            return this.$router.push('/vast/history')
          }
          // console.log(this.$data)
          // Object.assign(this.$data, baseState)
          // decode reports

          // if (r.data && r.data.vastconfig_inspections.length) {
          //   console.log(r.data)
          //   r.data.vastconfig_inspections.forEach(e => {
          //     e.report = JSON.parse(e.report_json)
          //   })
          // }
          console.log(r)
          this.vastConfig = r.data
          // this.setVastConfigInspectionIndex()
          // this.defineRedirect()
          // this.nbVendors()
          // this.findViewability()
          this.isLoading = false
          this._findAndEmitFirstVideoUrl()

          // todo : should we move this to a computed ?
          this.widgetMediafilesVendorsHistoryData.value = this.getMediafilesVendorsHistoryDataGraphValues(this.vastConfig.vastconfig_inspections)
          this.widgetAllDomainsHistoryData.value = this.getAllDomainsHistoryDataGraphValues(this.vastConfig.vastconfig_inspections)
        })
    },
    getAllDomainsHistoryDataGraphValues (inspections) {
      // graphs //
      let graphValues = {
        'columns': {
          '0': 'Day'
        },
        'colors': {
          '0': null
        },
        'dailylinks': {
          '0': ''
        },
        'data': {
          '0': {
            '0': '2020-01-01'
          }
        }
      }
      let columnsCounter = 0
      let inspectionsCounter = 0
      if (Array.isArray(inspections)) {
        for (const inspection of inspections) {
          graphValues.data[inspectionsCounter] = graphValues.data[inspectionsCounter] || {}
          graphValues.data[inspectionsCounter][0] = moment(inspection.created).format('MMM DD HH:mm')
          for (const ads of inspection.report.VAST.Ads) {
            // All Vendors Urls
            if (ads.hostVendors) {
              for (const [propertyName, propertyValue] of Object.entries(ads.hostVendors)) {
                const domainName = propertyName.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
                const columnIndex = Object.keys(graphValues.columns).find(key => graphValues.columns[key] === domainName)

                if (columnIndex !== undefined) {
                  graphValues.data[inspectionsCounter][columnIndex] = (graphValues.data[inspectionsCounter][columnIndex] || 0) + propertyValue.urls.length
                  // if (domainName === 'ade.googlesyndication.com') {
                  //   console.log('OK', columnIndex, domainName, graphValues.data[inspectionsCounter][columnIndex])
                  // }
                } else {
                  columnsCounter++
                  graphValues.columns[columnsCounter] = domainName
                  graphValues.colors[columnsCounter] = this.stringToColor(domainName)
                  graphValues.dailylinks[columnsCounter] = ''
                  graphValues.data[inspectionsCounter][columnsCounter] = propertyValue.urls.length
                  // if (domainName === 'ade.googlesyndication.com') {
                  //   console.log(propertyValue);
                  //   console.log('KO', columnIndex, domainName, graphValues.data[inspectionsCounter][columnIndex])
                  // }
                }
              }
            } else {
              // TODO : should be removed

              // creatives
              for (const creative of ads.creatives) {
                const domainName = creative.mediafile.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
                const columnIndex = Object.keys(graphValues.columns).find(key => graphValues.columns[key] === domainName)
                if (columnIndex) {
                  graphValues.data[inspectionsCounter][columnIndex] = (graphValues.data[inspectionsCounter][columnIndex] || 0) + 1
                } else {
                  columnsCounter++
                  graphValues.columns[columnsCounter] = domainName
                  graphValues.colors[columnsCounter] = this.stringToColor(domainName)
                  graphValues.dailylinks[columnsCounter] = ''
                  graphValues.data[inspectionsCounter][columnsCounter] = 1
                }
              }
              // errors
              for (const errors of ads.errors) {
                const domainName = errors.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
                const columnIndex = Object.keys(graphValues.columns).find(key => graphValues.columns[key] === domainName)
                if (columnIndex) {
                  graphValues.data[inspectionsCounter][columnIndex] = (graphValues.data[inspectionsCounter][columnIndex] || 0) + 1
                } else {
                  columnsCounter++
                  graphValues.columns[columnsCounter] = domainName
                  graphValues.colors[columnsCounter] = this.stringToColor(domainName)
                  graphValues.dailylinks[columnsCounter] = ''
                  graphValues.data[inspectionsCounter][columnsCounter] = 1
                }
              }
              // impressions
              for (const impression of ads.impressions) {
                const domainName = impression.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
                const columnIndex = Object.keys(graphValues.columns).find(key => graphValues.columns[key] === domainName)
                if (columnIndex) {
                  graphValues.data[inspectionsCounter][columnIndex] = (graphValues.data[inspectionsCounter][columnIndex] || 0) + 1
                } else {
                  columnsCounter++
                  graphValues.columns[columnsCounter] = domainName
                  graphValues.colors[columnsCounter] = this.stringToColor(domainName)
                  graphValues.dailylinks[columnsCounter] = ''
                  graphValues.data[inspectionsCounter][columnsCounter] = 1
                }
              }
              // trackingPixels
              for (const [propertyName, propertyValue] of Object.entries(ads.trackingPixels)) {
                const domainName = propertyName.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
                const columnIndex = Object.keys(graphValues.columns).find(key => graphValues.columns[key] === domainName)
                if (columnIndex) {
                  graphValues.data[inspectionsCounter][columnIndex] = (graphValues.data[inspectionsCounter][columnIndex] || 0) + propertyValue.urls.length
                } else {
                  columnsCounter++
                  graphValues.columns[columnsCounter] = domainName
                  graphValues.colors[columnsCounter] = this.stringToColor(domainName)
                  graphValues.dailylinks[columnsCounter] = ''
                  graphValues.data[inspectionsCounter][columnsCounter] = propertyValue.urls.length
                }
              }
            }
          }
          inspectionsCounter++
        }
      }
      // WTF piece of shit
      // for (let [propertyName, propertyValue] of Object.entries(graphValues.data)) {
      for (let propertyValue of Object.entries(graphValues.data)) {
        // console.debug(propertyName, propertyValue)
        for (let i = 0; i <= columnsCounter; i++) {
          if (!(propertyValue.hasOwnProperty(i))) {
            // console.debug('creating entry ' + i + ' in data[' + propertyName + ']')
            propertyValue[i] = 0
          } else {
            // console.debug('has prop ' + i)
          }
        }
      }
      return graphValues
    },
    getMediafilesDomainsHistoryDataGraphValues (inspections) {
      // graphs //
      let graphValues = {
        'columns': {
          '0': 'Day'
        },
        'colors': {
          '0': null
        },
        'dailylinks': {
          '0': ''
        },
        'data': {
          '0': {
            '0': '2020-01-01'
          }
        }
      }
      let columnsCounter = 0
      let inspectionsCounter = 0
      if (Array.isArray(inspections)) {
        for (const inspection of inspections) {

          graphValues.data[inspectionsCounter] = graphValues.data[inspectionsCounter] || {}
          graphValues.data[inspectionsCounter][0] = moment(inspection.created).format('MMM DD HH:mm')
          for (const ads of inspection.report.VAST.Ads) {
            for (const creative of ads.creatives) {
              const domainName = creative.mediafile.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
              // const vendorName = (creative.vendor_name === null) ? this.$t('under_qualification') : ('' + creative.vendor_name === 'undefined') ? this.$t('under_qualification') : creative.vendor_name
              // const vendorName = (creative.vendor_name === null) ? 'under_qualification' : ('' + creative.vendor_name === 'undefined') ? 'under_qualification' : creative.vendor_name
              const columnIndex = Object.keys(graphValues.columns).find(key => graphValues.columns[key] === domainName)
              if (columnIndex) {
                graphValues.data[inspectionsCounter][columnIndex] = (graphValues.data[inspectionsCounter][columnIndex] || 0) + 1
              } else {
                columnsCounter++
                graphValues.columns[columnsCounter] = domainName
                graphValues.colors[columnsCounter] = this.stringToColor(domainName)
                graphValues.dailylinks[columnsCounter] = ''
                graphValues.data[inspectionsCounter][columnsCounter] = 1
              }
            }
          }
        }
        inspectionsCounter++
      }
      // WTF piece of shit
      for (let [propertyName, propertyValue] of Object.entries(graphValues.data)) {
        console.debug(propertyName, propertyValue)
        for (let i = 0; i <= columnsCounter; i++) {
          if (!(propertyValue.hasOwnProperty(i))) {
            // console.debug('creating entry ' + i + ' in data[' + propertyName + ']')
            propertyValue[i] = 0
          } else {
            // console.debug('has prop ' + i)
          }
        }
      }
      return graphValues
    },
    getMediafilesVendorsHistoryDataGraphValues (inspections) {
      // graphs //
      let graphValues = {
        'columns': {
          '0': 'Day'
        },
        'colors': {
          '0': null
        },
        'dailylinks': {
          '0': ''
        },
        'data': {
          '0': {
            '0': '2020-01-01'
          }
        }
      }

      let columnsCounter = 0
      let inspectionsCounter = 0
      if (Array.isArray(inspections)) {
        for (const inspection of inspections) {
          graphValues.data[inspectionsCounter] = graphValues.data[inspectionsCounter] || {}
          graphValues.data[inspectionsCounter][0] = moment(inspection.created).format('MMM DD HH:mm')
          for (const ads of inspection.report.VAST.Ads) {
            for (const creative of ads.creatives) {
              const vendorName = (creative.vendor_name === null) ? this.$t('video.detail.unknown_vendor') : ('' + creative.vendor_name === 'undefined') ? this.$t('video.detail.unknown_vendor') : creative.vendor_name
              // const vendorName = (creative.vendor_name === null) ? 'under_qualification' : ('' + creative.vendor_name === 'undefined') ? 'under_qualification' : creative.vendor_name
              const columnIndex = Object.keys(graphValues.columns).find(key => graphValues.columns[key] === vendorName)
              if (columnIndex) {
                graphValues.data[inspectionsCounter][columnIndex] = (graphValues.data[inspectionsCounter][columnIndex] || 0) + 1
              } else {
                columnsCounter++
                graphValues.columns[columnsCounter] = vendorName
                graphValues.colors[columnsCounter] = this.stringToColor(vendorName)
                graphValues.dailylinks[columnsCounter] = ''
                graphValues.data[inspectionsCounter][columnsCounter] = 1
              }
            }
          }
          inspectionsCounter++
        }
      }
      // WTF piece of shit
      for (let [propertyName, propertyValue] of Object.entries(graphValues.data)) {
        console.debug(propertyName, propertyValue)
        for (let i = 0; i <= columnsCounter; i++) {
          if (!(propertyValue.hasOwnProperty(i))) {
            // console.debug('creating entry ' + i + ' in data[' + propertyName + ']')
            propertyValue[i] = 0
          } else {
            // console.debug('has prop ' + i)
          }
        }
      }
      return graphValues
    },

    // findViewability () {
    //   let firstVast = this.data.history[0].report.VAST
    //   if (!!firstVast.Ads[0].creatives[0] && !!firstVast.Ads[0].creatives[0].validator.viewability.desktop.status &&
    //     firstVast.Ads[0].creatives[0].validator.viewability.desktop.status === true
    //   ) {
    //     this.data.viewability = true
    //     this.vastConfig.viewability = true
    //   }
    // },

    // renderState () {
    //   return Object.assign({}, baseState)
    // },

    /**
     * Take all urls found in the response data
     */
    // nbVendors () {
    //   const {vastData, vendors, multipleAds} = this
    //   let arr = []
    //   let draft = []
    //   if (multipleAds) {
    //     for (const ad of vastData) {
    //       draft = [
    //         ad.creatives,
    //         ad.trackingEvents,
    //         ad.impressions,
    //         ad.errors,
    //         ad.trackingClick
    //       ]
    //       arr.push(...draft)
    //     }
    //   } else {
    //     arr = [
    //       vastData.creatives,
    //       vastData.trackingEvents,
    //       vastData.impressions,
    //       vastData.errors,
    //       vastData.trackingClick
    //     ]
    //   }
    //   let k = 0
    //   for (k; k < arr.length; k++) {
    //     if (!arr[k][0]) {
    //       continue
    //     }
    //     arr[k][0].mediafile && arr[k].forEach(media => vendors.push(media.mediafile))
    //     arr[k][0].value && arr[k].forEach(event => vendors.push(event.value))
    //     typeof arr[k][0] === 'string' && arr[k].forEach(url => vendors.push(url))
    //   }
    // },

    /**
     * Open Api response and
     * handle Ads found in the VAST
     */
    // setVastConfigInspectionIndex (inspectionNb = 0) {
    //   const {data} = this
    //   if (data.inspections) {
    //     const vast = data.inspections[inspectionNb].report.VAST
    //     this.vastData = vast.Ads
    //     this.multipleAds = (vast.Ads.length > 1)
    //   }
    //   const conf = JSON.parse(JSON.stringify(data))
    //   // delete conf.inspections
    //   this.vastConfig = conf
    //   // console.log('vast', vast)
    //   // if (vast.Ads.length <= 1) {
    //   //   this.multipleAds = false
    //   //  this.vastData = vast.Ads[0]
    //   // } else {
    //   //   this.multipleAds = true
    //   //   this.vastData = vast.Ads
    //   // }
    // },

    defineRedirect () {
      // const {vastData, multipleAds, vastConfig} = this
      const {vastData, vastConfig} = this
      vastConfig['adIsRedirect'] = []
      if (vastConfig.vastconfig_inspections) {
        for (let i = 0; i < vastData.length; i++) {
          vastData[i].isRedirect = vastData[i].hasOwnProperty('VASTAdTagURI')
          vastConfig['adIsRedirect'].push({
            adId: i,
            isRedirect: true
          })
        }
      }
      // } else {
      //   vastData.isRedirect = vastData.hasOwnProperty('VASTAdTagURI')
      //   vastConfig.adIsRedirect = {
      //     isRedirect: false
      //   }
      // }
    },

    vendorRedirect (vendor) {
      this.$router.push('/vendor/' + vendor.vendor_name)
    }
  }
}
</script>
<style lang="scss">
  @import "video-css/videojs.ads.css";
  @import "video-css/video-js.css";
</style>

<style lang="scss">

  /**
   * Copyright 2014 Google Inc.
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   *     http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   */

  header, footer {
    text-align: center;
    width: 100%;
  }

  header {
    height: 40px;
    font-size: 40px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  footer {
    margin-top: 20px;
  }

  video {
    max-width: 100%;
  }

  .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
    height: auto;
  }

  .inner-container{
    min-height: 0;
  }
  #ima-sample-container {
    margin-left: auto;
    margin-right: auto;
    width: 728px;
  }

  .urlLink {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  #ima-sample-videoplayer {
    position: relative;
    background-color: #000;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(50, 50, 50, 0.95);
    border: 2px #ccc solid;
    width: 640px;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  #content_video {
    overflow: hidden;
  }

  #ima-sample-playlistDiv {
    height: 122px;
    width: 224px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .ima-sample-playlistItem {
    height: 122px;
    width: 102px;
    float: left;
    margin-right: 10px;
    cursor: pointer;
  }

  .ima-sample-playlistImg {
    border: 1px solid;
  }

  #ima-sample-console {
    font-family: courier, monospace;
    font-size: 12px;
    margin-top: 20px;
    height: 200px;
    width: 630px;
    padding: 5px;
    border: 1px #ccc solid;
    overflow-y: scroll;
    margin-left: auto;
    margin-right: auto;
  }

  #ima-sample-companionDiv {
    width: 728px;
    height: 90px;
    margin-top: 20px;
  }

  /**
   * Copyright 2014 Google Inc.
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   *     http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   */

  .ima-ad-container {
    top: 0em;
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
  }

  /* Move overlay if user fast-clicks play button. */
  .video-js.vjs-playing .bumpable-ima-ad-container {
    margin-top: -4em;
  }

  /* Move overlay when controls are active. */
  .video-js.vjs-user-inactive.vjs-playing .bumpable-ima-ad-container {
    margin-top: 0em;
  }

  .video-js.vjs-paused .bumpable-ima-ad-container,
  .video-js.vjs-playing:hover .bumpable-ima-ad-container,
  .video-js.vjs-user-active.vjs-playing .bumpable-ima-ad-container {
    margin-top: -4em;
  }

  .ima-controls-div {
    bottom: 0em;
    height: 1.4em;
    position: absolute;
    overflow: hidden;
    display: none;
    opacity: 1;
    background-color: rgba(7, 20, 30, .7);
    background: -moz-linear-gradient(
        bottom,
        rgba(7, 20, 30, .7) 0%,
        rgba(7, 20, 30, 0) 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0%, rgba(7, 20, 30, .7)),
        color-stop(100%, rgba(7, 20, 30, 0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        bottom,
        rgba(7, 20, 30, .7) 0%,
        rgba(7, 20, 30, 0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(bottom,
      rgba(7, 20, 30, .7) 0%,
      rgba(7, 20, 30, 0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(bottom,
      rgba(7, 20, 30, .7) 0%,
      rgba(7, 20, 30, 0) 100%); /* IE10+ */
    background: linear-gradient(to top,
      rgba(7, 20, 30, .7) 0%,
      rgba(7, 20, 30, 0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#0007141E',
        endColorstr='#07141E', GradientType=0); /* IE6-9 */
  }

  .ima-controls-div.ima-controls-div-showing {
    height: 3.7em;
  }

  .ima-countdown-div {
    height: 1em;
    color: #FFFFFF;
    text-shadow: 0 0 0.2em #000;
    cursor: default;
  }

  .ima-seek-bar-div {
    top: 1.2em;
    height: 0.3em;
    position: absolute;
    background: rgba(255, 255, 255, .4);
  }

  .ima-progress-div {
    width: 0em;
    height: 0.3em;
    background-color: #ECC546;
  }

  .ima-play-pause-div, .ima-mute-div, .ima-slider-div, .ima-fullscreen-div {
    width: 2.33em;
    height: 1.33em;
    top: 0.733em;
    left: 0em;
    position: absolute;
    color: #CCCCCC;
    font-size: 1.5em;
    line-height: 2;
    text-align: center;
    font-family: VideoJS;
    cursor: pointer;
  }

  .ima-mute-div {
    left: auto;
    right: 5.667em;
  }

  .ima-slider-div {
    left: auto;
    right: 2.33em;
    width: 3.33em;
    height: 0.667em;
    top: 1.33em;
    background-color: #555555;
  }

  .ima-slider-level-div {
    width: 100%;
    height: 0.667em;
    background-color: #ECC546;
  }

  .ima-fullscreen-div {
    left: auto;
    right: 0em;
  }

  .ima-playing:before {
    content: "\00f103";
  }

  .ima-paused:before {
    content: "\00f101";
  }

  .ima-playing:hover:before, .ima-paused:hover:before {
    text-shadow: 0 0 1em #fff;
  }

  .ima-non-muted:before {
    content: "\00f107";
  }

  .ima-muted:before {
    content: "\00f104";
  }

  .ima-non-muted:hover:before, .ima-muted:hover:before {
    text-shadow: 0 0 1em #fff;
  }

  .ima-non-fullscreen:before {
    content: "\00f108";
  }

  .ima-fullscreen:before {
    content: "\00f109";
  }

  .ima-non-fullscreen:hover:before, .ima-fullscreen:hover:before {
    text-shadow: 0 0 1em #fff;
  }

</style>
<style lang="scss" scoped>
  /*.campaign {*/
  /*  .check-icon {*/
  /*    color: green;*/
  /*    font-size: 30px;*/
  /*    position: absolute;*/
  /*    right: 0;*/
  /*    top: 0;*/
  /*    bottom: 0;*/
  /*  }*/

  /*  .warn-icon {*/
  /*    color: #ddb757;*/
  /*    font-size: 20px;*/
  /*    position: absolute;*/
  /*    right: 5px;*/
  /*    top: 5px;*/
  /*    bottom: 0;*/
  /*  }*/

  /*  .danger-icon {*/
  /*    color: #dd6a57e0;*/
  /*    font-size: 20px;*/
  /*    position: absolute;*/
  /*    right: 5px;*/
  /*    top: 5px;*/
  /*    bottom: 0;*/
  /*  }*/

  /*  .actions {*/
  /*    margin-right: 10px;*/

  /*    i {*/
  /*      margin-left: 5px;*/
  /*    }*/
  /*  }*/

  /*  .ivu-card {*/
  /*    border-radius: 0.25rem;*/
  /*    -webkit-box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);*/
  /*    box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);*/
  /*    margin-bottom: 30px*/
  /*  }*/

  /*  .card-ul {*/
  /*    display: inline-block;*/
  /*    vertical-align: top;*/

  /*    li {*/
  /*      font-weight: lighter;*/
  /*      position: relative;*/
  /*      padding: 2px 3px;*/
  /*      margin: 10px 0;*/
  /*    }*/

  /*    li a {*/
  /*      font-weight: 500;*/
  /*      font-size: 20px;*/
  /*      vertical-align: middle;*/
  /*      padding-left: 10px;*/
  /*    }*/

  /*    !*span {*!*/
  /*    !*  position: absolute;*!*/
  /*    !*  width: 180px;*!*/
  /*    !*  white-space: nowrap;*!*/
  /*    !*  overflow: hidden;*!*/
  /*    !*  text-overflow: ellipsis;*!*/
  /*    !*  height: 25px;*!*/
  /*    !*  left: 280px;*!*/
  /*    !*  font-size: 16px;*!*/
  /*    !*  font-weight: normal;*!*/
  /*    !*  padding-right: 5px;*!*/
  /*    !*}*!*/
  /*  }*/

  /*  .container {*/
  /*    width: 100%;*/
  /*    display: flex;*/
  /*    flex-wrap: wrap;*/
  /*    justify-content: space-between;*/

  /*    &::after {*/
  /*      content: '';*/
  /*    }*/
  /*  }*/
  /*}*/

  /*body {*/
  /*  font-family: arial, verdana, sans-serif;*/
  /*  overflow: hidden;*/
  /*}*/

  /*header, footer {*/
  /*  text-align: center;*/
  /*  width: 100%;*/
  /*}*/

  /*header {*/
  /*  height: 40px;*/
  /*  font-size: 40px;*/
  /*  font-weight: 500;*/
  /*  margin-top: 20px;*/
  /*  margin-bottom: 20px;*/
  /*}*/

  /*footer {*/
  /*  margin-top: 20px;*/
  /*}*/

  #ima-sample-container {
    margin-left: auto;
    margin-right: auto;
    width: 728px;
  }

  /*.urlLink {*/
  /*  color: blue;*/
  /*  text-decoration: underline;*/
  /*  cursor: pointer;*/
  /*}*/

  #ima-sample-videoplayer {
    position: relative;
    background-color: #000;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(50, 50, 50, 0.95);
    border: 2px #ccc solid;
    width: 640px;
    height: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  /*#content_video {*/
  /*  overflow: hidden;*/
  /*}*/

  #ima-sample-playlistDiv {
    height: 122px;
    width: 224px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .ima-sample-playlistItem {
    height: 122px;
    width: 102px;
    float: left;
    margin-right: 10px;
    cursor: pointer;
  }

  .ima-sample-playlistImg {
    border: 1px solid;
  }

  #ima-sample-console {
    font-family: courier, monospace;
    font-size: 12px;
    margin-top: 20px;
    height: 200px;
    width: 630px;
    padding: 5px;
    border: 1px #ccc solid;
    overflow-y: scroll;
    margin-left: auto;
    margin-right: auto;
  }

  #ima-sample-companionDiv {
    width: 728px;
    height: 90px;
    margin-top: 20px;
  }

</style>
