import {mapGetters} from "vuex"
import moment from "moment"

const columnsMixin = {
  data () {
    return {
      riskColor: {
        grey: '#616a6894',
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      },
      columns: [
        // {
        //   title: this.$t('video.detail.customer'),
        //   key: 'customer_id',
        //   sortable: true,
        //   render: (h, params) => h('span', params.row.customer.name)
        // },
        // {
        //   title: this.$t('Campaign type'),
        //   key: 'campaign',
        //   sortable: "custom",
        //   sortKey:"Vastconfig.campaign",
        // },
        {
          title: this.$t('video.detail.advertiser'),
          key: 'advertiser',
          sortable: "custom",
          sortKey:"Vastconfigs.advertiser",
        },
        {
          title: this.$t('Identifier'),
          key: 'creative_id',
          sortable: "custom",
          sortKey:"Vastconfigs.creative_id",
        },

        {
          title: this.$t('video.detail.last_check_date'),
          key: 'modified',
          sortable: "custom",
          sortKey:"Vastconfigs.modified",
          render: (h, params) => h("span", {}, moment(params.row.modified).format('LL'))
        },
        {
          width: 85,
          title: this.$t('Risk'),
          key: 'last_vendors_vigilance_rating',
          sortable: false,
          render: (h, params) => {
            return h('span',
              {
                attrs: {
                  style: "color: "+ this.riskColor[this.vendorVigilanceToLetter(params.row.last_vendors_vigilance_rating*5)]
                }
              },
              Math.round(params.row.last_vendors_vigilance_rating * 100) +'%')
          }
        },

        // {
        //   title: 'status',
        //   key: 'last_status',
        //   width: 100,
        //   sortable: true
        // },
        {
          title: ' ',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  // style: {
                  //   marginRight: '5px'
                  // },
                  on: {
                    click: () => {
                      this.$router.push('/vast/vast_config/' + params.row.id)
                    }
                  }
                },
                'Analysis'
              )
            ])
          }
        }
      ]
    }
  },
  methods: {
    vendorVigilanceToLetter: (status) => {
      if (!!status === false) return 'grey'
      return String.fromCharCode(parseInt(Math.ceil(status)) + 64)
    },
  }
}

export default columnsMixin
