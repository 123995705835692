var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Drawer', {
    attrs: {
      "title": _vm.isDraft ? _vm.$t('video.detail.save_control') : _vm.$t('video.detail.edit_control'),
      "width": "720",
      "mask-closable": false,
      "styles": _vm.styles
    },
    model: {
      value: _vm.opened,
      callback: function callback($$v) {
        _vm.opened = $$v;
      },
      expression: "opened"
    }
  }, [_vm.isLoading ? _c('Spin', {
    attrs: {
      "size": "large",
      "fix": ""
    }
  }) : _c('Form', {
    ref: "editForm",
    staticClass: "edit-form",
    attrs: {
      "model": _vm.formData
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('video.detail.campaign_name'),
      "prop": "name"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.data.campaign
    },
    model: {
      value: _vm.formData.campaign,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "campaign", $$v);
      },
      expression: "formData.campaign"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    staticClass: "models",
    attrs: {
      "label": _vm.$t('video.detail.sale_types')
    }
  }, [_c('Select', {
    model: {
      value: _vm.formData.saletype_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "saletype_id", $$v);
      },
      expression: "formData.saletype_id"
    }
  }, _vm._l(_vm.models, function (item) {
    return _c('Option', {
      key: item.name,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_vm.showTrading ? _c('FormItem', {
    attrs: {
      "label": "Trading desk",
      "label-position": "top"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "please enter the description"
    },
    model: {
      value: _vm.formData.tradingdesk,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "tradingdesk", $$v);
      },
      expression: "formData.tradingdesk"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "demo-drawer-footer"
  }, [_c('Button', {
    staticStyle: {
      "margin-right": "8px"
    },
    on: {
      "click": function click() {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.global.cancel')) + " ")]), _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.saveChanges
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.global.save')) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }