var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "campaign"
  }, [_c('div', {
    staticClass: "header-page"
  }, [_c('span', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t('video.detail.results')))])]), !_vm.isLoading && !!_vm.vastConfig ? _c('div', [_c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.configuration')) + " ")]), !_vm.isLoading ? _c('edit-drawer', {
    attrs: {
      "is-draft": _vm.draft,
      "is-editing": _vm.isEditing,
      "data": _vm.vastConfig
    },
    on: {
      "close": _vm.closeDrawer,
      "created": _vm.getCreated,
      "updated": _vm.updateData
    }
  }) : _vm._e(), _c('Row', {
    attrs: {
      "gutter": 22
    }
  }, [_c('Col', {
    attrs: {
      "lg": 12
    }
  }, [_c('table', [_c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.campaign')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastConfig.campaign || _vm.dataNull))])]), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.advertiser')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastConfig.advertiser || _vm.dataNull))])]), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.agency')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastConfig.agency || _vm.dataNull))])]), _vm.vastConfig.dealmodel === 'Direct' ? _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.tradingdesk')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastConfig.tradingdesk || _vm.dataNull))])]) : _vm._e(), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.url_vast')) + " ")]), _c('td', [_vm._v(" : "), _c('a', {
    attrs: {
      "href": _vm.vastConfig.url,
      "target": "_blank"
    }
  }, [_vm._v("url")])])]), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.last_check_date')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.dateSimple(_vm.vastConfig.modified) || _vm.dataNull))])])])]), !!_vm.videoUrl ? _c('Col', {
    attrs: {
      "lg": 12
    },
    on: {
      "emitUrl": _vm.updateVid
    }
  }, [_c('video', {
    attrs: {
      "controls": "",
      "src": _vm.videoUrl
    }
  })]) : _vm._e()], 1)], 1), _c('br'), _vm.vastConfig.vastconfig_inspections && _vm.vastConfig.vastconfig_inspections.length > 0 ? _c('Timeline', _vm._l(_vm.vastConfig.vastconfig_inspections, function (inspection, inspectionIdx) {
    return _c('TimelineItem', {
      key: inspection.id,
      ref: "inspections",
      refInFor: true
    }, [[_c('h4', {
      on: {
        "click": function click($event) {
          return _vm.setVastConfigInspectionFocus(inspectionIdx);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.dateSimple(inspection.created)) + " ")])], inspection.report.VAST ? _c('div', [_vm.vastConfigInspectionIndex != inspectionIdx ? _c('Card', [_c('Steps', {
      attrs: {
        "current": -1
      }
    }, [_vm._l(inspection.report.VAST.Ads, function (vastAd, adIdx) {
      return _c('Step', {
        key: adIdx,
        attrs: {
          "title": "".concat(vastAd.creatives.length && vastAd.creatives[0].vendor_name || _vm.$t('Freewheel')),
          "content": "".concat(vastAd.creatives.length && vastAd.creatives[0].vendor_category_name || _vm.$t('video.detail.unknown_category'))
        }
      });
    }), _vm._v("--> ")], 2)], 1) : _c('Card', [_c('Tabs', {
      attrs: {
        "type": "card"
      }
    }, _vm._l(inspection.report.VAST.Ads, function (vastAd, key) {
      return _c('TabPane', {
        key: key,
        attrs: {
          "label": "".concat(vastAd.creatives.length && vastAd.creatives[0].vendor_name || _vm.$t('Freewheel Inventory'))
        }
      }, [_c('AdContent', {
        attrs: {
          "vast-ad": vastAd,
          "loading": _vm.isLoading,
          "vast-config": _vm.vastConfig
        }
      })], 1);
    }), 1)], 1)], 1) : _c('div', [_c('Card', [_vm._v(" " + _vm._s(inspection.report.status) + " ")])], 1)], 2);
  }), 1) : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }