<template>
  <Spin
    v-if="isLoading"
    size="large"
    fix
  />
  <div v-else>
    <!--  <Row class="container" type="flex" v-if="vastAd.isContainVpaid">-->
    <!--  </Row>-->
    <Row :gutter="22">
      <Col :lg="12">
        <!--        <Card>-->
        <!--          <p slot="title">{{$t('video.detail.summary')}}</p>-->
        <table>
          <tr v-if="gradeVendorVigilance()">
            <th align="left">
              Ad vendors average Trust Index
            </th>
            <td>
              : <span :style="{color: riskColor[vendorVigilanceToLetter(gradeVendorVigilance())]}"> {{ vendorVigilanceToLetter(gradeVendorVigilance()) }}</span>
              <!--              <br/><small> ({{ vendor_vigilance_rating || dataNull }})</small>-->
            </td>
          </tr>
          <tr>
            <th align="left">
              Ad Id
            </th>
            <td>: {{ vastAd.adId || dataNull }}</td>
          </tr>
          <tr>
            <th align="left">
              Ad Type
            </th>
            <td>: {{ vastAd.isWrapper ? 'Wrapper' : 'Inline' }}</td>
          </tr>
          <tr>
            <th align="left">
              {{ $t('video.detail.vpaid_found') }}
            </th>
            <td>: {{ vastAd.isContainVpaid ? $t('video.global.yes') : $t('video.global.no') }}</td>
          </tr>
          <tr>
            <th align="left">
              {{ $t('video.detail.vpaid_viewability') }}
            </th>
            <td>: {{ vastConfig.viewability ? $t('video.global.yes') : $t('video.global.no') }}</td>
          </tr>
          <tr>
            <th align="left">
              {{ $t('video.detail.redirects_found') }}
            </th>
            <td>: {{ (vastConfig.adIsRedirect && vastConfig.adIsRedirect.length > 0) ? $t('video.global.yes') : $t('video.global.no') }} </td>
          </tr>
        </table>
        <!--        </Card>-->
      </Col>
      <Col :lg="12">
        <WidgetTile
          :api-res="widgetVendorsData"
          :is-actionable="true"
          type="small"
        />
      </Col>
      <!--      <Col :lg="5">-->
      <!--        <WidgetTile-->
      <!--          :api-res="widgetCategoriesData"-->
      <!--          :is-actionable="true"-->
      <!--          type="small"-->
      <!--        />-->
      <!--      </Col>-->
    </Row>
    <br>

    <Details
      v-if="vastAd"
      :data="vastAd"
      :is-loading="isLoading"
      :vendors="vendors"
    />
  </div>
</template>

<script>
import eventColumns from './columnsMixin/eColumns'
import WidgetTile from '@/components/ui/WidgetTile'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import Details from './AdContentDetail'
import {eventBus} from '@/main'
import {mapGetters} from "vuex"
// import EditDrawer from './VastConfigEdit'

export default {
  name: 'CampaignAdcontent',
  mixins: [eventColumns],
  components: {WidgetTile, Details},
  note: 'vast result content',
  props: {
    vastAd: {
      type: Object,
      default: () => {
      },
      'note': 'the ads analyzed from VAST'
    },
    loading: {
      type: Boolean,
      default: true,
      'note': 'loading status'
    },
    domains: {
      type: Array,
      default: () => [],
      'note': 'the amount of domains found in the VAST'
    },
    vendors: {
      type: Array,
      default: () => [],
      'note': 'the amount of vendors found in the VAST'
    },
    vastConfig: {
      type: Object,
      default: () => {
      },
      note: 'the vast configuration'
    }
  },
  watch: {
    loading: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.isLoading = newVal
        this.getVendorsDetails()
        console.log('popopo')
        this.videoUrl()
      }
    }
  },
  mounted () {
    this.buildWidgets()
  },
  data () {
    const loading = this.loading
    return {
      riskColor: {
        grey: '#616a6894',
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      },
      vendor_vigilance_rating: null,
      dataNull: 'unspecified data',
      eventBus: eventBus,
      vendorsType: [],
      globalWarning: {},
      videoThubnail: false,
      nbCheck: 0,
      nbErrors: 0,
      isLoading: loading,
      vendorsByTypo: null,
      percentTypo: null,
      redirect: {},
      widgetVendorsData: {
        'paging': {},
        'filters': {},
        'name': 'VENDORS',
        'properties': {
          '0': 'propertyId'
        },
        'startDate': '2018-05-29',
        'endDate': '2018-06-29',
        'nbDays': 32,
        'propertyNames': {
          '0': 'propertyName'
        },
        'widget': 'DONUT',
        'title': 'VENDORS',
        'help': '',
        'export': {
          'csv': ''
        },
        'value': {
          '0': {
            'color': '#81C327',
            'field': 'Hardcoded',
            'value': 48,
            'link': ''
          }
        },
        'isExportableCsv': false,
        'csvFileName': 'vendors_by_name'
      },
      widgetCategoriesData: {
        'paging': {},
        'filters': {},
        'name': 'CATEGORIES',
        'properties': {
          '0': 'propertyId'
        },
        'startDate': '2018-05-29',
        'endDate': '2018-06-29',
        'nbDays': 32,
        'propertyNames': {
          '0': 'propertyName'
        },
        'widget': 'DONUT',
        'title': 'CATEGORIES',
        'help': '',
        'export': {
          'csv': ''
        },
        'value': {
          '0': {
            'color': '#81C327',
            'field': 'Hardcoded',
            'value': 48,
            'link': ''
          }
        },
        'isExportableCsv': false,
        'csvFileName': 'vendors_by_name'
      }
    }
  },
  computed: {
    ...mapGetters({
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter'
    }),
    formats () {
      const {vastAd} = this
      const formats = vastAd.creatives[0].validator.viewability
      return Object.keys(formats)
    }
  },
  methods: {
    gradeVendorVigilance () {
      // let globalVigilance = 0
      // if (this.vendor.country.vigilance_id && parseFloat(this.vendor.country.vigilance_id) > 0) {
      //   globalVigilance += (parseFloat(this.vendor.country.vigilance_id) * 0.3)
      // } else {
      //   globalVigilance += (3 * 0.3)
      // }
      // if (this.vendor.category.vigilance_id && parseFloat(this.vendor.category.vigilance_id) > 0) {
      //   globalVigilance += (parseFloat(this.vendor.category.vigilance_id) * 0.1)
      // } else {
      //   globalVigilance += (3 * 0.1)
      // }
      // if (this.vendor.iabvendors2 && this.vendor.iabvendors2.vigilance_rating && parseFloat(this.vendor.country.vigilance_id) > 0) {
      //   globalVigilance += (parseFloat(this.gradeTcfVigilance(this.vendor.iabvendors2.vigilance_rating) * 0.6))
      // } else {
      //   globalVigilance += (4 * 0.6)
      // }
      // return (globalVigilance)
      return this.vendor_vigilance_rating * 5
    },
    buildWidgets () {
      let vendors = {}
      let categories = {}
      let vendors_vigilance_ratings = 0
      let vendors_count = 0
      for (let [key, vendor] of Object.entries(this.vastAd.hostVendors)) {
        console.log(key, vendor)
        if (!vendors[vendor.vendor_name]) {
          vendors[vendor.vendor_name] = {
            'color': '#' + (Math.random() * 0xFFFFFF << 0).toString(16),
            'field': (vendor.vendor_name !== null) ? vendor.vendor_name : this.$t('video.detail.unknown_vendor'),
            'value': 1,
            'link': ''
          }
          if(vendor.vendor_vigilance_rating){
            vendors_vigilance_ratings += vendor.vendor_vigilance_rating
            vendors_count++
          }
        } else {
          vendors[vendor.vendor_name].value += 1
        }

        if (!categories[vendor.vendor_category_name]) {
          categories[vendor.vendor_category_name] = {
            'color': '#' + (Math.random() * 0xFFFFFF << 0).toString(16),
            'field': (vendor.vendor_category_name !== null) ? vendor.vendor_category_name : this.$t('video.detail.unknown_category'),
            'value': 1,
            'link': ''
          }
        } else {
          categories[vendor.vendor_category_name].value += 1
        }
      }

      if(vendors_count > 0){
        this.vendor_vigilance_rating = vendors_vigilance_ratings / vendors_count
      }
      this.widgetVendorsData.value = vendors
      this.widgetCategoriesData.value = categories
    },
    /**
     * Map creatives array to find a
     * creative url which can be use
     * by the html5 player
     *
     * @returns {String} the first url found or empty string
     */
    videoUrl () {
      const {creatives} = this.vastAd
      if (creatives.length <= 0) {
        return false
      }
      const url = creatives.find(creative => {
        return creative.mediafile.includes('mp4') ||
        creative.mediafile.includes('Ogg') ||
        creative.mediafile.includes('webm')
          ? creative.mediafile
          : false
      })
      eventBus.$emit('vid-url', url ? url.mediafile : '')
    },

    // percentTypo (vendor) {
    //   const { vastAd } = this
    //   const nbVendors = Object.values(vastAd.trackingPixels)
    //   let draft = {}
    //   nbVendors.forEach(v => {
    //     draft.hasOwnProperty(v.widget.category.name)
    //       ? draft[v.widget.category.name] += 1
    //       : draft[v.widget.category.name] = 1
    //   })
    // },
    getVendorsDetails () {
      const vnds = Object.values(this.vastAd.trackingPixels)
      const ids = vnds.filter(vendor => typeof vendor.vendor_id === 'number').map(vendor => vendor.vendor_id)
      const tht = this
      let mespromesses = ids.map(id => {
        return tht.$axios.get('vendors/view/' + id)
          .then(r => {
            return vnds.find(vendor => {
              if (vendor.vendor_id === id) {
                (vendor.widget = r.data.widget)
              }
            })
          })
      })
      Promise.all(mespromesses)
        .then(() => this.getVendorsTypology())
    },

    getVendorsTypology () {
      const vnds = Object.values(this.vastAd.trackingPixels)
      vnds.forEach(vendor => {
        if (!vendor.widget) {
          vendor.widget = {
            category: {
              name: 'unknow'
            }
          }
        }
      })
      const category = [...new Set(vnds.map(vendor => vendor.widget.category.name))]
      let arr = []
      let draft = {}
      category.forEach(cat => {
        draft = {
          name: cat,
          nb: 0
        }
        vnds.filter(vendor => {
          vendor.widget.category.name === cat && (draft.nb += vendor.urls.length)
        })
        arr.push(draft)
      })
      const {vastAd} = this
      const nbVendors = Object.values(vastAd.trackingPixels)
      let droft = {}
      nbVendors.forEach(v => {
        if (droft.hasOwnProperty(v.widget.category.name)) {
          droft[v.widget.category.name] = droft[v.widget.category.name] + 1
        } else {
          droft[v.widget.category.name] = 1
        }
      })
      this.percentTypo = droft
      this.vendorsByTypo = arr
    },

    errorClass (error) {
      return error
        ? 'li-warning'
        : ''
    }
    // https://staging-api.facettes.io/vendors/view/141?access_token=4d02ace9e6b1529f65d5aed312ed9ba355e01965
    // Request URL: https://staging-api.facettes.io/domains/updatejson/141
    // widget33 vendors par property date obligatoire //

  }
}
</script>

<style lang="scss" scoped>
/*  .campaign {
    .check-icon {
      color: green;
      font-size: 30px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .warn-icon {
      color: #ddb757;
      font-size: 20px;
      position: absolute;
      right: 5px;
      top: 5px;
      bottom: 0;
    }

    .danger-icon {
      color: #dd6a57e0;
      font-size: 20px;
      position: absolute;
      right: 5px;
      top: 5px;
      bottom: 0;
    }

    .actions {
      margin-bottom: 20px;

      i {
        margin-left: 5px;
      }
    }

    .ivu-card {
      border-radius: 0.25rem;
      -webkit-box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
      margin-bottom: 30px
    }

    .card-ul {
      display: inline-block;
      vertical-align: top;

      li {
        font-weight: lighter;
        position: relative;
        padding: 2px 3px;
        margin: 10px 0;
      }

      li a {
        font-weight: 500;
        font-size: 20px;
        vertical-align: middle;
        padding-left: 10px;
      }

      span {
        position: absolute;
        width: 180;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 25px;
        left: 290px;
        font-size: 16px;
        font-weight: normal;
        padding-right: 5px;
      }
    }

    .container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &::after {
        content: '';
      }
    }
  }*/
</style>

<style lang="scss">
 /* .campaign {
    .bad-res {
      text-align: left;
      display: block;
      padding: 5px;
      margin: 3px 0;
      background: #ec7c6880;
      border-radius: 3px;
    }

    .good-res {
      text-align: left;
      display: block;
      padding: 5px;
      margin: 3px 0;
      background: #49b36173;
      border-radius: 3px;
    }

    .summary-technical {
      .ivu-card-body {
        display: flex;
        justify-content: space-between;
      }

      li {
        padding: 3px;
      }

      .li-warning {
        background: #ffbd4173;
        margin: 3px 0;
        border-radius: 3px;
      }
    }

    .summary-business {
      .ivu-card-body {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;

        .vendors-typology {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding: 0 20px;
          box-sizing: border-box;
          flex-wrap: wrap;
        }
      }
    }
  }*/
</style>
