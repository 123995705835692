const eventsColumns = {
  data () {
    return {
      eventsCols: [
        {
          title: this.$t('video.detail.event_name'),
          key: 'name',
          width: 200
        },
        {
          title: 'Url',
          ellipsis: true,
          key: 'value',
          render: (h, params) => {
            const text = params.row.value
            return h(
              'router-link',
              {
                props: {
                  to: text
                }
              },
              text
            )
          }
        }
      ]
    }
  }
}

export default eventsColumns
