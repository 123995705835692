<template>
  <div>
    <Drawer
      :title="isDraft ? $t('video.detail.save_control') : $t('video.detail.edit_control')"
      v-model="opened"
      width="720"
      :mask-closable="false"
      :styles="styles"
    >
      <Spin
        v-if="isLoading"
        size="large"
        fix
      />
      <Form
        :model="formData"
        ref="editForm"
        class="edit-form"
        v-else
      >
        <Row :gutter="32">
          <Col span="24">
            <FormItem
              :label="$t('video.detail.campaign_name')"
              prop="name"
            >
              <Input
                type="text"
                v-model="formData.campaign"
                :placeholder="data.campaign"
              />
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem
              :label="$t('video.detail.sale_types')"
              class="models"
            >
              <Select v-model="formData.saletype_id">
                <Option
                  v-for="item in models"
                  :value="item.id"
                  :key="item.name"
                >
                  {{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <!-- <Col span="24">
            <FormItem
              :label="$t('video.detail.agency')"
              prop="agency"
              search
              autocomplete
              class="agency"
            >
              <AutoComplete
                v-model="formData.agency"
                :data="searchAgency"
                @on-search="getAgencies"
                @on-select="mapAgency"
                placeholder="OMD"
              />
            </FormItem>
          </Col> -->
          <!-- <Col span="24">
            <FormItem
              :label="$t('video.detail.advertiser')"
              prop="advertiser"
            >
              <AutoComplete
                v-model="formData.advertiser"
                :data="advertisersOptions"
                @on-search="getAdvs"
                @on-select="mapAdv"
                placeholder="ORANGE"
              />
            </FormItem>
          </Col> -->
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem
              label="Trading desk"
              v-if="showTrading"
              label-position="top"
            >
              <Input
                type="text"
                v-model="formData.tradingdesk"
                placeholder="please enter the description"
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="demo-drawer-footer">
        <Button
          style="margin-right: 8px"
          @click="()=>$emit('close')"
        >
          {{ $t('video.global.cancel') }}
        </Button>
        <Button
          type="primary"
          @click="saveChanges"
        >
          {{ $t('video.global.save') }}
        </Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  props: {
    isEditing: {
      type: Boolean,
      default: false,
      note: 'is the drawer open'
    },
    data: {
      type: Object,
      default: () => {},
      note: 'vast data'
    },
    isDraft: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      agenceJson: [],
      models: [],
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        paddingLeft: '20px',
        position: 'static'
      },
      agencies: [],
      isLoading: false,
      searchAgency: [],
      advertisersOptions: [],
      agcObj: null,
      advObject: null,
      formData: {
        id: '',
        campaign: '',
        url: '',
        agency: '',
        saletype_id: '',
        advertiser: '',
        tradingdesk: ''
      }
    }
  },
  mounted () {
    const { data, formData } = this
    // this.getTypes()
    for (const key in formData) {
      if (key === 'campaignName') {
        formData['campaignName'] = data['campaign']
      } else {
        formData[key] = data[key]
      }
    }
  },
  computed: {
    opened: {
      get: function () {
        return this.isEditing
      },
      set: function () {
        this.$emit('close')
      }
    },
    showTrading () {
      const { formData } = this
      return (formData.saleType !== 'Direct') && (formData.saleType !== '')
    }
  },
  methods: {
    ...mapActions({
      getVast: 'video/view',
      addVast: 'video/add',
      editVast: 'video/edit',
      getSaleTypes: 'video/getSaleTypes',
      searchByAdv: 'video/searchByAdv',
      searchByAgncy: 'video/searchByAgncy'
    }),

    /**
     * Save VAST config changes & update the vast check
     */
    saveChanges () {
      // const payload = {}
      this.isLoading = true
      // payload.id = this.data.id
      const payload = Object.assign({}, this.formData)
      if (!this.showTrading) {
        payload.saletype_id = null
        payload.tradingdesk = null
      }
      if (this.isDraft) {
        payload.url = this.data.url
        delete payload.id
        return this.addVast(payload)
          .then(resp => {
            console.log('isDraft save response data', resp.data)
            this.isLoading = false
            this.$Message.success('Success!')
            this.$router.push('/vast/vast_config/' + resp.data.id)
            this.$emit('created', resp.data.id)
          })
      }
      return this.editVast(payload)
        .then(resp => {
          this.isLoading = false
          this.$Message.success('Success!')
          this.$emit('updated', this.formData)
        })
    },

    getTypes () {
      return this.getSaleTypes()
        .then(r => {
          this.models = Object.values(r.data.widget)
        })
    },

    /**
    * Define possible advertisers
    * dependig to selected agency
    */
    mapAgency (value) {
      if (!this.agcObj) {
        return
      }
      const agency = this.agcObj.find(elm => elm.name.toUpperCase() === value.toUpperCase())
      if (!agency) { return }
      const params = {
        agenceId: agency.id
      }
      return this.searchByAdv({params})
        .then(r => {
          this.advertisersOptions = Object.values(r.data.widget).map(advertiser => advertiser.name)
        })
    },

    /**
    * Define possible agencies
    * dependig to selected advertiser
    */
    mapAdv (value) {
      if (!this.advObject) {
        return
      }
      const advertiser = this.advObject.find(elm => elm.name.toUpperCase() === value.toUpperCase())
      if (!advertiser) {
        return
      }
      const params = {
        advertiserId: advertiser.id
      }
      return this.searchByAgncy({params})
        .then(r => {
          this.searchAgency = Object.values(r.data.widget).map(agency => agency.name)
        })
    },

    /**
     * Define possible agency depending to
     * actual search
     */
    getAgencies (value, elm) {
      if (value.length < 2) {
        return false
      }
      const params = {agence: value}
      return this.searchByAgncy({params})
        .then(r => {
          const agcs = Object.values(r.data.widget).map(agency => agency.name)
          this.agcObj = Object.values(r.data.widget)
          this.agencies = agcs
          const auto = []
          const val = value.toLowerCase()
          agcs.forEach(agence => {
            const agc = agence.toLowerCase()
            agc.toLowerCase().includes(val) && auto.push(agc)
          })
          this.searchAgency = !value ? [] : auto
        })
    },

    /**
     * Define possible advertisers depending to
     * actual search
     */
    getAdvs (value) {
      if (value.length < 2) {
        return false
      }
      const params = {advertiser: value}
      return this.searchByAdv({params})
        .then(r => {
          const advs = Object.values(r.data.widget).map(agency => agency.name)
          this.advObject = Object.values(r.data.widget)
          this.agencies = advs
          const auto = []
          const val = value.toLowerCase()
          advs.forEach(advertiser => {
            const adv = advertiser.toLowerCase()
            adv.toLowerCase().includes(val) && auto.push(adv)
          })
          this.advertisersOptions = !value ? [] : auto
        })
    }
  }
}
</script>
<style scoped lang="scss">
    .demo-drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
    }
    .edit-form {
      .ivu-row {
        padding-bottom: 15px;
        margin: 10px 0;
      }
      .ivu-col {
        margin: 10px 0;
      }
      .ivu-input{
        background-color: #fff !important;
      }
    }
</style>
