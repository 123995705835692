<template>
  <div class="details widget">
    <Spin
      v-if="loading"
      size="large"
      fix
    />
    <Card v-else>
      <p slot="title">
        {{ $t('video.detail.detailed') }}
      </p>

      <!--      <p slot="extra">-->
      <!--        <label>Business</label>-->
      <!--        <Switch v-model="showTech" />-->
      <!--        <label>Specs</label>-->
      <!--      </p>-->

      <!--      <div v-if="!!showTech">-->
      <div>
        <p>Mediafiles : {{ data.creatives.length }}</p>
        <Table
          v-if="data.creatives.length > 0"
          stripe
          :columns="mediafilesCols"
          :data="prepareData()"
        />

        <!--          <Table v-if="data.creatives.length > 0"-->
        <!--                 stripe :columns="mediaCols"-->
        <!--                :data="prepareData()"></Table>-->

        <p>{{ $t('video.detail.tracking_url') }} : {{ Object.values(data.trackingEvents).length }}</p>
        <Table
          v-if="Object.values(data.trackingEvents).length > 0"
          stripe
          :columns="eventsCols"
          :data="Object.values(data.trackingEvents)"
        />

        <p>{{ $t('video.detail.active_vendors') }} : {{ Object.values(data.trackingPixels).length }}</p>
        <Table
          v-if="Object.values(data.trackingPixels).length > 0"
          stripe
          :columns="schema"
          :data="Object.values(data.trackingPixels)"
        />
      </div>
      <hr>
    </Card>
  </div>
</template>

<script>
// import ExpendMedia from './ExpendMedia'
import MediafileValidations from './MediafileValidations'
import vendorsColumns from './columnsMixin/vColumns'
import {eventBus} from '@/main'
import eventsColumns from './columnsMixin/eColumns'

export default {
  name: 'DetailCampaign',
  mixins: [vendorsColumns, eventsColumns],
  component: { MediafileValidations },
  props: {
    data: {
      type: Object,
      default: () => {},
      note: 'the vast data'
    },
    isLoading: {
      type: Boolean
    },
    vendors: {
      type: Array,
      default: () => [],
      notes: 'all vendors found'
    }
  },
  data () {
    return {
      showTech: true,
      loading: this.isLoading,
      eventBus: eventBus,
      domains: [],
      vendorsUrl: [],
      panel: 'allDomains',
      vendorsList: [
        {
          title: this.$t('video.detail.domain'),
          key: 'domain',
          width: 300
        },
        {
          title: this.$t('video.detail.nb'),
          key: 'count',
          width: 100
        }
      ],
      vendorsListUrl: [
        {
          title: this.$t('video.detail.domain'),
          key: 'domain',
          width: 300
        },
        {
          title: 'Url',
          key: 'url',
          render: function (h, params) {
            const url = params.row.url
            return h('a', {}, url)
          }
        }
      ],
      extensions: [
        {
          'Country': 'FR',
          'Bandwidth': '4',
          'BandwidthKbps': '13640'
        },
        {
          'AdServingData': {
            'DeliveryData': {
              'GeoData': 'ct=FR&st=&city=4984&dma=0&zp=92200&bw=4'
            }
          }
        }
      ],
      mediaCols: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(MediafileValidations, {
              props: {
                row: params.row,
                spec: this.tf1Specs
              }
            })
          }
        },
        {
          title: 'Url',
          key: 'mediafile',
          render: (h, params) => {
            const text = params.row.mediafile
            return h('a', {}, text)
          }
        },
        {
          title: 'Format',
          key: 'type',
          width: 150
        }
      ],
      mediafilesCols: [
        {

          type: 'expand',
          width: 30,
          render: (h, params) => {
            return h(MediafileValidations, {
              props: {
                row: params.row,
                spec: this.tf1Specs
              }
            })
          }

        },

        {
          title: 'Type',
          render: (h, params) => {
            // return h('b', ((params.row.validator.viewability.desktop.status || params.row.validator.viewability.mobile.status || params.row.validator.viewability.inApp.status || params.row.validator.viewability.ipTv.status) ? 'viewability' : '') + ' ' + params.row.type)
            return h('b', params.row.type)
          }
        },
        {
          title: 'Vendor',
          align: 'left',
          width: 180,
          render: (h, params) => {
            return h('span', params.row.vendor_name ? params.row.vendor_name : '')
          }
        },
        // {
        //   title: 'Bitrate',
        //   align: 'right',
        //   width: 100,
        //   render: (h, params) => {
        //     return h('span', params.row.bitrate ? params.row.bitrate + ' kbit/s' : '')
        //   }
        // },
        {
          title: 'Desktop',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Icon', {
              props: {
                type: params.row.validator.all && params.row.validator.all.desktop.status ? 'md-checkmark-circle' : 'md-remove',
                size: 16
              }
            })
          }
        },
        {
          title: 'Mobile',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Icon', {
              props: {
                type: params.row.validator.all && params.row.validator.all.mobile.status ? 'md-checkmark-circle' : 'md-remove',
                size: 16
              }
            })
          }
        },
        {
          title: 'InApp',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Icon', {
              props: {
                type: params.row.validator.all && params.row.validator.all.inApp.status ? 'md-checkmark-circle' : 'md-remove',
                size: 16
              }
            })
          }

        },
        {
          title: 'IPTV',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Icon', {
              props: {
                type: params.row.validator.all && params.row.validator.all.ipTv.status ? 'md-checkmark-circle' : 'md-remove',
                size: 16
              }
            })
          }

        }
        // {
        //   title: 'Url',
        //   key: 'mediafile',
        //   render: (h, params) => {
        //     const text = params.row.mediafile
        //     return h('a', {}, text)
        //   }
        // },

      ]
    }
  },

  /**
   * extract domains from vendors found
   * create an object "this.domains" which contains
   * the domain name and the amount
   */
  mounted () {
    eventBus.$on('techDetails', this.techDetails)
    let k = 0
    // domain rgx
    const rgx = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
    let domains = []
    for (k; k < this.vendors.length; k++) {
      this.vendorsUrl.push({
        domain: this.vendors[k].match(rgx)[0],
        url: this.vendors[k]
      })
      if (domains.indexOf(this.vendors[k].match(rgx)[0]) >= 0) {
        this.domains.find(obj => {
          if (obj.domain === this.vendors[k].match(rgx)[0]) {
            obj.count += 1
          }
        })
        continue
      }
      domains.push(this.vendors[k].match(rgx)[0])
      this.domains.push({
        count: 1,
        domain: this.vendors[k].match(rgx)[0]
      })
    }
  },
  watch: {
    isLoading: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loading = newVal
      }
    }
  },
  methods: {
    techDetails () {
      // this.showTech = true
      // window.scrollTo(0, 1800)
    },

    // expand first element
    prepareData () {
      const { data } = this
      // const arr = Object.values(data.creatives).reverse()
      const arr = Object.values(data.creatives)
      // arr[0]._expanded = true
      return Object.values(arr)
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  margin-bottom: 15px;
  position: relative;
  .switch-mode {
    position: absolute;
    top: 15px;
    right: 30px;
  }
  .ivu-spin-fix {
      top: 25px !important;
      left: 25px !important;
      right: 25px !important;
      bottom: 25px !important;
      width: auto !important;
      height: auto !important;
  }
  .base-config {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    span {
      width: 100%;
      margin: 5px 0;
    }
  }
  .spec-menu {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    label {
      padding: 0 10px;
    }
    h3 {
      margin-right: 30px;
    }
  }
  /*.content-1 {*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  flex-wrap: wrap;*/
  /*  justify-content: space-between;*/
  /*  align-items: flex-start;*/
  /*  .ivu-collapse-item {*/
  /*    border-top: 0;*/
  /*    border-bottom: 1px solid #ebebeb;*/
  /*  }*/
  /*  .ivu-card-head {*/
  /*    border-top: none*/
  /*  }*/
  /*  .pixels {*/
  /*    margin-bottom: 10px;*/
  /*      li {*/
  /*      overflow-wrap: break-word;*/
  /*      padding: 3px 0;*/
  /*      position: relative;*/
  /*        .li-after {padding-bottom: 25px}*/
  /*        .li-after::after{*/
  /*          content: '';*/
  /*          width: 100%;*/
  /*          height: 1px;*/
  /*          background: #ebebeb;*/
  /*          position: absolute;*/
  /*          left: 0px;*/
  /*          bottom: 0px;*/
  /*        }*/
  /*      }*/
  /*  }*/
  /*}*/
}
</style>
