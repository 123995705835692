import qs from 'qs'

const STATUS_MAPPING = Object.freeze({
  W: {
    status: 'Whitelisted',
    about: 'This domain is whitelisted',
    iconClass: 'fa fa-check fa-lg'
  },
  B: {
    status: 'Under surveillance',
    about: 'This domain is under surveillance',
    iconClass: 'fa fa-eye fa-lg'
  },
  U: {
    status: 'Undefined',
    about: "This domain's status is undefined",
    iconClass: 'fa fa-question fa-lg'
  }
})

const vendorColumns = {
  data () {
    return {
      schema: [
        {
          title: this.$t('video.detail.name'),
          slot: 'vendor',
          sortable: true,
          key: 'vendor_name',
          render: (h, params) => {
            if (params.row.vendor_name) {
              return h(
                'Tooltip',
                // {
                //   props: {
                //     content: "View this vendor's sheet"
                //   }
                // },
                [
                  h(
                    'span',
                    {
                      attrs: {
                        style: 'font-weight: 600;'
                      },
                      // on: {
                      //   click: () => {
                      //     this.$router.push({
                      //       name: 'Vendor',
                      //       params: { name: params.row.vendor_name }
                      //     })
                      //   }
                      // }
                    },
                    params.row.vendor_name
                  )
                ]
              )
            } else {
              return h(
                'Tooltip',
                // {
                //   props: {
                //     content: 'This vendor is under qualification'
                //   }
                // },
                [
                  h(
                    'Button',
                    {
                      props: {
                        type: 'text',
                        disabled: true
                      }
                    },
                    'Vendor under qualification'
                  )
                ]
              )
            }
          }
        },
        {
          title: this.$t('video.detail.domain'),
          key: 'vendor_name',
          sortable: true,
          render: (h, params) => {
            const rgx = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
            const urls = Object.values(params.row.urls)
            const domain = urls[0].match(rgx)[0]
            return h(
              'Tooltip',
              {
                props: {
                  content: 'Click to view all domains for this vendor',
                  maxWidth: 200
                }
              },
              [
                h(
                  'strong',
                  {
                    attrs: {
                      class: 'clickable-el heavy'
                    },
                    on: {
                      click: () => {
                        this.panels.manageDomains = {
                          ...this.panels.manageDomains,
                          state: true,
                          params: {
                            status: 'ALL',
                            vendorId: params.row.vendor
                              ? params.row.vendor_id
                              : null,
                            propertyId: params.row.property
                              ? params.row.property.id
                              : null,
                            hasVendor: !!params.row.vendor
                          },
                          header: {
                            vendorName: params.row.vendor
                              ? params.row.vendor_name
                              : null,
                            propertyName: params.row.property
                              ? params.row.property.displayName
                              : null
                          }
                        }
                      }
                    }
                  },
                  domain
                )
              ]
            )
          }
        },
        {
          title: this.$t('video.detail.nb'),
          width: 150,
          sortable: true,
          render: function (h, params) {
            const amount = Object.values(params.row.urls).length
            return h(
              'tag',
              {
                style: 'width: 40px; margin: 0 auto; text-align:center',
                color: 'primary'
              },
              JSON.stringify(amount)
            )
          }
        }
      ]
    }
  },
  methods: {
    updateDomainStatus (domain, status) {
      console.log(domain)
      if (!domain || !status) {
        return this.$Message.error('Domain or Status are missing...')
      }

      this.$Modal.confirm({
        title: 'Confirm your action',
        content: `
            Do you confirm to update status to <strong>${
  STATUS_MAPPING[status].status
}</strong> for domain
            <strong>${domain.name || 'Under qualification'}</strong> and vendor
            <strong>${
  domain.vendor ? domain.vendor_name : 'Under qualification'
}</strong>?`,
        okText: 'Yes, I do',
        cancelText: 'cancel',
        onOk: async () => {
          this.isUpdating = true
          try {
            const responseData = await this.$axios.post(
              `/domains/updatejson/${domain.vendor_id}`,
              qs.stringify({ status })
            )
            this.isUpdating = false
            if (responseData.data.success) {
              this.$Message.success('Changes have been saved!')
              this.fetch()
            } else {
              this.$Message.error('An error has occurred...')
            }
          } catch (err) {
            this.isUpdating = false
            this.$Message.error('Server sent back an error...')
            console.error('handleSubmit', err)
          }
        },
        onCancel: () => {
          return false
        }
      })
    }
  }
}
export default vendorColumns
