var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "video-history"
  }, [_c('div', {
    staticClass: "header-page"
  }, [_c('h1', [_vm._v(_vm._s(_vm.$t('VAST inventory')))])]), _c('Card', [_c('FilterTable', {
    attrs: {
      "search-filters": ['search', 'riskLevel', 'videoType'],
      "limit": 20,
      "border": true,
      "stripe": true,
      "columns": _vm.columns,
      "fetch-items": _vm.fetch
    },
    on: {
      "on-items-fetched": _vm.callback
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }