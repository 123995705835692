<template>
  <div v-if="row">
    <Row>
      <Col :lg="{span:7, offset:1}">
        <b>Network</b>
        <ul>
          <li><span class="expand-key">Is SSL: </span><span>{{ row.mediafile.includes('https') }}</span></li>
          <li><span class="expand-key">Type: </span><span>{{ row.type }}</span></li>
          <li><span class="expand-key">Bitrate: </span><span>{{ row.bitrate }}</span></li>
          <li><span class="expand-key">Delivery: </span><span>{{ row.delivery }}</span></li>
        </ul>
      </Col>
      <Col :lg="{span:7, offset:1}">
        <b>Presentation</b>
        <ul>
          <li><span class="expand-key">Width: </span><span>{{ row.width }}</span></li>
          <li><span class="expand-key">Height: </span><span>{{ row.height }}</span></li>
          <li><span class="expand-key">Scalable: </span><span>{{ row.scalable }}</span></li>
          <li><span class="expand-key">MaintainAspectRatio: </span><span>{{ row.maintainAspectRatio }}</span></li>
        </ul>
      </Col>
      <Col
        v-if="row.loudness"
        :lg="{span:7, offset:1}"
      >
        <b>Audio</b>
        <ul>
          <li
            v-for="(val, key) in filteredLoudness"
            :key="key"
          >
            <span class="expand-key">{{ key }}: </span><span>{{ val }}</span>
          </li>
        </ul>
        i : integrated (LUFS) /
        tp : true peak (dBTP) /
        lra : loudness range (LU) /
        tresh : threshold (LUFS) /
      </Col>
      <Col
        v-if="row.apiFramework"
        :lg="{span:7, offset:1}"
      >
        <ul>
          <li><span class="expand-key">ApiFramework: </span><span>{{ row.apiFramework }}</span></li>
        </ul>
      </Col>
    </Row>
    <Row>
      <Col :lg="{span:23, offset:1}">
        <a
          :href="row.mediafile"
          target="_blank"
        >{{ row.mediafile }}</a>
      </Col>
    </Row>
    <Table
      :columns="mediafilesCols"
      :data="mediafilesData"
    />
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: ()=> {},
    },
    spec: { 
      type:Object,
      default: () => {},
    }
  },
  data () {
    return {
      mediafilesData: [],
      deviceCols: [
        {
          title: 'Desktop',
          key: 'desktop'
        },
        {
          title: 'Mobile',
          key: 'mobile'
        },
        {
          title: 'In App',
          key: 'inApp'
        },
        {
          title: 'IP TV',
          key: 'ipTv'
        }
      ],
      resolutionCols: [
        {
          title: 'Resolution',
          key: 'res'
        },
        {
          title: 'Desktop',
          key: 'desktop'
        },
        {
          title: 'Mobile',
          key: 'mobile'
        },
        {
          title: 'In App',
          key: 'inApp'
        },
        {
          title: 'IP TV',
          key: 'ipTv'
        }
      ],
      mediafilesCols: [
        {
          title: ' ',
          width: 30
        },
        {
          title: ' ',
          key: 'name'
        },
        {
          title: '',
          width: 180,
          render: (h, params) => {
            return h('span', params.row.desktop.more.value)
          }
        },
        {
          title: 'Desktop',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Tooltip',
              {
                props: {
                  content: params.row.desktop.more.message
                }
              },
              [
                h('Icon',
                  {
                    props: {
                      type: params.row.desktop.status !== null ? params.row.desktop.status === 1 ? 'md-checkmark-circle-outline' : 'md-close' : '',
                      size: 16
                    }
                  }
                )
              ]
            )
          }
        },
        {
          title: 'Mobile',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Tooltip',
              {
                props: {
                  content: params.row.mobile.more.message
                }
              },
              [
                h('Icon',
                  {
                    props: {
                      type: params.row.mobile.status !== null ? params.row.mobile.status === 1 ? 'md-checkmark-circle-outline' : 'md-close' : '',
                      size: 16
                    }
                  }
                )
              ]
            )
          }

        },
        {
          title: 'InApp',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Tooltip',
              {
                props: {
                  content: params.row.inApp.more.message
                }
              },
              [
                h('Icon',
                  {
                    props: {
                      type: params.row.inApp.status !== null ? params.row.inApp.status === 1 ? 'md-checkmark-circle-outline' : 'md-close' : '',
                      size: 16
                    }
                  }
                )
              ]
            )
          }

        },
        {
          title: 'IPTV',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return h('Tooltip',
              {
                props: {
                  content: params.row.ipTv.more.message
                }
              },
              [
                h('Icon',
                  {
                    props: {
                      type: params.row.ipTv.status !== null ? params.row.ipTv.status === 1 ? 'md-checkmark-circle-outline' : 'md-close' : '',
                      size: 16
                    }
                  }
                )
              ]
            )
          }

        }
      ]
    }
  },
  mounted () {
    this.mediafilesData = (Object.keys(this.row.validator).map((key) => {
      if (!Array.isArray(this.row.validator[key])) {
        let entry = this.row.validator[key]
        entry.name = key
        return entry
      } else {
        return null
      }
    }).filter(x => x && x.name !== 'all') // hide entry for 'all' validator and remove nulls/empties
    )
    // this.plop = this.row.validator
  },
  computed: {
    filteredLoudness () {
      const { row } = this
      const filtered = []
      Object.entries(row).forEach((key, val) => {
        if (key.indexOf('input') > -1) filtered.push(val)
      })
      return filtered
    },
    dataFileType () {
      const types = this.row.validator.mediafileType
      const arr = []
      let draft = {
        cellClassName: {}
      }
      for (let [key, value] of Object.entries(types)) {
        value.more.length <= 0
          ? draft[key] = 'Bad file type'
          : draft[key] = value.more[0]
        draft.cellClassName[key] = !value.status
          ? 'tech-ok'
          : 'tech-issue'
      }
      arr.push(draft)
      return arr
    },

    viewability () {
      const types = this.row.validator.viewability
      const arr = []
      let draft = {
        cellClassName: {}
      }
      for (let [key, value] of Object.entries(types)) {
        value.more.length <= 0
          ? draft[key] = 'No wrappers'
          : draft[key] = Object.keys(value.more)[0]
        draft.cellClassName[key] = !value.status
          ? 'tech-well'
          : 'tech-ok'
      }
      arr.push(draft)
      return arr
    },

    mediafileSize () {
      const sizes = this.row.validator.sizeLimit
      const arr = []
      let draft = {
        cellClassName: {}
      }
      for (let [key, value] of Object.entries(sizes)) {
        draft[key] = value.status
          ? 'Ok'
          : 'Bad size'
        draft.cellClassName[key] = value.status
          ? 'tech-ok'
          : 'tech-issue'
      }
      arr.push(draft)
      return arr
    },

    mediaResolution () {
      const sizes = this.row
      const arr = []
      let draftW = {
        res: 'width',
        cellClassName: {}
      }
      let draftH = {
        res: 'height',
        cellClassName: {}
      }
      const allCols = [draftW, draftH]
      for (const [key, val] of Object.entries(sizes)) {
        draftW[key] = val.more.width
          ? 'Ok'
          : 'Bad size'
        draftW.cellClassName[key] = val.more.width
          ? 'tech-ok'
          : 'tech-issue'

        draftH[key] = val.more.height
          ? 'Ok'
          : 'Bad size'
        draftH.cellClassName[key] = val.more.height
          ? 'tech-ok'
          : 'tech-issue'
      }
      arr.push(...allCols)
      return arr
    }
  },

  methods: {
    isResOk (res) {
      const validator = this.row.validator
      return validator.resolution[res].status
    },
    isVideoTypeOk (url) {
      return (url.includes('mp4') || url.includes('ogg') || url.includes('webm'))
    },
    isSpecValid (media, elm) {
      const {warnings} = media
      if (elm === 'type') {
        return !warnings.type
      }
    }
  }
}
</script>
