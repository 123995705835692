var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.row ? _c('div', [_c('Row', [_c('Col', {
    attrs: {
      "lg": {
        span: 7,
        offset: 1
      }
    }
  }, [_c('b', [_vm._v("Network")]), _c('ul', [_c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Is SSL: ")]), _c('span', [_vm._v(_vm._s(_vm.row.mediafile.includes('https')))])]), _c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Type: ")]), _c('span', [_vm._v(_vm._s(_vm.row.type))])]), _c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Bitrate: ")]), _c('span', [_vm._v(_vm._s(_vm.row.bitrate))])]), _c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Delivery: ")]), _c('span', [_vm._v(_vm._s(_vm.row.delivery))])])])]), _c('Col', {
    attrs: {
      "lg": {
        span: 7,
        offset: 1
      }
    }
  }, [_c('b', [_vm._v("Presentation")]), _c('ul', [_c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Width: ")]), _c('span', [_vm._v(_vm._s(_vm.row.width))])]), _c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Height: ")]), _c('span', [_vm._v(_vm._s(_vm.row.height))])]), _c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Scalable: ")]), _c('span', [_vm._v(_vm._s(_vm.row.scalable))])]), _c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("MaintainAspectRatio: ")]), _c('span', [_vm._v(_vm._s(_vm.row.maintainAspectRatio))])])])]), _vm.row.loudness ? _c('Col', {
    attrs: {
      "lg": {
        span: 7,
        offset: 1
      }
    }
  }, [_c('b', [_vm._v("Audio")]), _c('ul', _vm._l(_vm.filteredLoudness, function (val, key) {
    return _c('li', {
      key: key
    }, [_c('span', {
      staticClass: "expand-key"
    }, [_vm._v(_vm._s(key) + ": ")]), _c('span', [_vm._v(_vm._s(val))])]);
  }), 0), _vm._v(" i : integrated (LUFS) / tp : true peak (dBTP) / lra : loudness range (LU) / tresh : threshold (LUFS) / ")]) : _vm._e(), _vm.row.apiFramework ? _c('Col', {
    attrs: {
      "lg": {
        span: 7,
        offset: 1
      }
    }
  }, [_c('ul', [_c('li', [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("ApiFramework: ")]), _c('span', [_vm._v(_vm._s(_vm.row.apiFramework))])])])]) : _vm._e()], 1), _c('Row', [_c('Col', {
    attrs: {
      "lg": {
        span: 23,
        offset: 1
      }
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.row.mediafile,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.row.mediafile))])])], 1), _c('Table', {
    attrs: {
      "columns": _vm.mediafilesCols,
      "data": _vm.mediafilesData
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }