<template>
  <main class="video-history">
    <div class="header-page">
      <h1>{{ $t('Player payload') }}</h1>
    </div>
    <Card>
      <FilterTable
        :search-filters="['search', 'riskLevel']"
        :fetch-items-params="{origin: 'payload'}"
        :limit="20"
        :border="true"
        :stripe="true"
        :columns="payloadColumns"
        :fetch-items="fetch"
        @on-items-fetched="callback"
      />
    </Card>
  </main>
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import columnMixin from '@/views/Vast/campaign/columnsMixin/payloadColumns'
import FilterTable from '@/components/ui/FilterTable'

export default {
  name: 'VideoHistory',
  mixins: [columnMixin],
  components: {FilterTable},
  data () {
    return {
      data: null,
      loading: true
    }
  },
  methods: {
    ...mapActions({
      getVastConfigs: 'video/getVastConfigs'
    }),
    callback(res) {
      this.data = res.data
    },
    fetch ({params, uid = this._uid}) {
      return this.getVastConfigs(params)
        .then(r => r)
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  .main-content {
  min-height: 100vh;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  position: relative;
  .ivu-spin-fix {
    top: 25px !important;
    left: 25px !important;
    right: 25px !important;
    bottom: 25px !important;
    width: auto !important;
    height: auto !important;
  }
  .container {
    width:100%;
    padding: 10px 20px;
    box-sizing: border-box;
    .ivu-table-wrapper {
      border: 1px solid #ebebeb;
    }
  }
}
}

</style>
<style lang="scss">
.video-history {
  .ivu-table-header{
    border-bottom: 1px solid #ebebeb !important;
    padding-bottom: 15px;
  }
}
</style>
