var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('Spin', {
    attrs: {
      "size": "large",
      "fix": ""
    }
  }) : _c('div', [_c('Row', {
    attrs: {
      "gutter": 22
    }
  }, [_c('Col', {
    attrs: {
      "lg": 12
    }
  }, [_c('table', [_vm.gradeVendorVigilance() ? _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" Ad vendors average Trust Index ")]), _c('td', [_vm._v(" : "), _c('span', {
    style: {
      color: _vm.riskColor[_vm.vendorVigilanceToLetter(_vm.gradeVendorVigilance())]
    }
  }, [_vm._v(" " + _vm._s(_vm.vendorVigilanceToLetter(_vm.gradeVendorVigilance())))])])]) : _vm._e(), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" Ad Id ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastAd.adId || _vm.dataNull))])]), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" Ad Type ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastAd.isWrapper ? 'Wrapper' : 'Inline'))])]), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.vpaid_found')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastAd.isContainVpaid ? _vm.$t('video.global.yes') : _vm.$t('video.global.no')))])]), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.vpaid_viewability')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastConfig.viewability ? _vm.$t('video.global.yes') : _vm.$t('video.global.no')))])]), _c('tr', [_c('th', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.redirects_found')) + " ")]), _c('td', [_vm._v(": " + _vm._s(_vm.vastConfig.adIsRedirect && _vm.vastConfig.adIsRedirect.length > 0 ? _vm.$t('video.global.yes') : _vm.$t('video.global.no')) + " ")])])])]), _c('Col', {
    attrs: {
      "lg": 12
    }
  }, [_c('WidgetTile', {
    attrs: {
      "api-res": _vm.widgetVendorsData,
      "is-actionable": true,
      "type": "small"
    }
  })], 1)], 1), _c('br'), _vm.vastAd ? _c('Details', {
    attrs: {
      "data": _vm.vastAd,
      "is-loading": _vm.isLoading,
      "vendors": _vm.vendors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }