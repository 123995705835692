var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "details widget"
  }, [_vm.loading ? _c('Spin', {
    attrs: {
      "size": "large",
      "fix": ""
    }
  }) : _c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('video.detail.detailed')) + " ")]), _c('div', [_c('p', [_vm._v("Mediafiles : " + _vm._s(_vm.data.creatives.length))]), _vm.data.creatives.length > 0 ? _c('Table', {
    attrs: {
      "stripe": "",
      "columns": _vm.mediafilesCols,
      "data": _vm.prepareData()
    }
  }) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$t('video.detail.tracking_url')) + " : " + _vm._s(Object.values(_vm.data.trackingEvents).length))]), Object.values(_vm.data.trackingEvents).length > 0 ? _c('Table', {
    attrs: {
      "stripe": "",
      "columns": _vm.eventsCols,
      "data": Object.values(_vm.data.trackingEvents)
    }
  }) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$t('video.detail.active_vendors')) + " : " + _vm._s(Object.values(_vm.data.trackingPixels).length))]), Object.values(_vm.data.trackingPixels).length > 0 ? _c('Table', {
    attrs: {
      "stripe": "",
      "columns": _vm.schema,
      "data": Object.values(_vm.data.trackingPixels)
    }
  }) : _vm._e()], 1), _c('hr')])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }